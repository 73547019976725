import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class DeploymentSuccessRateGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    // setup the bar graph series (last one gets the line pattern)
    const colorConfig = {
      succeeded: {
        bg: controller.darkMode ? controller.tailwindColors.teal[700] : controller.tailwindColors.teal[200],
        hover: controller.darkMode ? controller.tailwindColors.teal[900] : controller.tailwindColors.teal[300]
      },
      failed: {
        bg: controller.darkMode ? controller.tailwindColors.red[700] : controller.tailwindColors.red[200],
        hover: controller.darkMode ? controller.tailwindColors.red[800] : controller.tailwindColors.red[300]
      }
    }

    const succeededSeriesData = []
    const failedSeriesData = []
    const emptySeriesData = []
    for (let i = 0; i < controller.parsedGraphData.data.categories.length; i++) {
      let succeededData = controller.parsedGraphData.data.succeeded[i]
      if (!succeededData) {
        // add the missing datapoint
        succeededData = {
          x: controller.parsedGraphData.data.categories[i],
          y: 0
        }
      }
      let failedData = controller.parsedGraphData.data.failed[i]
      if (!failedData) {
        // add the missing datapoint
        failedData = {
          x: controller.parsedGraphData.data.categories[i],
          y: 0
        }
      }
      if (i === controller.parsedGraphData.data.categories.length - 1) {
        succeededData = { ...succeededData, ...this.getLastDataPointPattern(colorConfig.succeeded) }
        failedData = { ...failedData, ...this.getLastDataPointPattern(colorConfig.failed) }
      }
      succeededSeriesData.push(succeededData)
      failedSeriesData.push(failedData)
    }

    let series = [{}]
    if (controller.parsedGraphData.max > 0) {
      series = [
        {
          name: 'Succeeded',
          data: succeededSeriesData,
          zIndex: 1,
          borderRadiusTopLeft: 0,
          borderRadiusTopRight: 0,
          legendIndex: 0,
          minPointLength: 0,
          yAxis: 0,
          color: controller.darkMode ? controller.tailwindColors.teal[700] : controller.tailwindColors.teal[200],
          states: {
            hover: {
              color: controller.darkMode ? controller.tailwindColors.teal[900] : controller.tailwindColors.teal[300]
            }
          },
          marker: { enabled: false }
        },
        {
          name: 'Failed',
          data: failedSeriesData,
          zIndex: 1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          legendIndex: 0,
          minPointLength: 0,
          yAxis: 0,
          color: controller.darkMode ? controller.tailwindColors.red[700] : controller.tailwindColors.red[200],
          states: {
            hover: {
              color: controller.darkMode ? controller.tailwindColors.red[800] : controller.tailwindColors.red[300]
            }
          },
          marker: { enabled: false }
        }
      ]
    }

    if (emptySeriesData.length) {
      this.addNoDataSeriesToSeries(series, emptySeriesData)
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickPositions: controller.parsedGraphData.data.categories,
        tickLength: 0,
        labels: {
          step: controller.parsedGraphData.data.categories.length > 6 ? 3 : 1,
          formatter: function () {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: [
        {
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && !controller.parsedGraphData.max) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...maxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openDeploymentDrilldown(new Date(this.x).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const total = this.points.reduce((acc, p) => {
            if (['Succeeded', 'Failed'].includes(p.series.name)) {
              return acc + p.y
            }
            return acc
          }, 0)
          const breakDownValues = this.points.map((p, i) => {
            if (p.series.name === 'No data') {
              return null
            } else {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              const type = 'box'
              let value = p.y
              let percent = 0
              let style
              switch (p.series.name) {
                default:
                  if (total > 0) percent = Math.round((p.y / total) * 100)
                  value = `${p.y} <span class="text-gray-600 dark:text-gray-400">(${percent}%)</span>`
                  style = `background-color: ${color};`
              }
              return breakDownValue({
                name: p.series.name,
                value,
                style,
                type
              })
            }
          })

          const endDate = controller.getEndDate(
            this.x, controller.optionsValue.date_granularity
          )
          const date = `${Highcharts.dateFormat('%B %e', this.x)}` + ' - ' + `${Highcharts.dateFormat('%B %e', endDate)}`

          return card({
            date: date,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-action="click->deployment-success-rate-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View deployments</button>`,
            incomplete: this.points[0].point.index === (controller.parsedGraphData.data.categories - 1),
            breakDownValues
          })
        }
      }
    }
  }
}
