import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="comparison-table"
export default class extends Controller {
  static outlets = ['comparison-chart']

  initialize () {
    this.allOptionChecked = true
  }

  legendMouseOver (event) {
    if (!this.hasComparisonChartOutlet) return

    const name = event.currentTarget.dataset.name
    if (!name) return

    this.comparisonChartOutlets.forEach(chart => { chart.legendMouseOver(name) })
  }

  legendMouseOut () {
    if (!this.hasComparisonChartOutlet) return

    this.comparisonChartOutlets.forEach(chart => { chart.legendMouseOut() })
  }

  legendClicked (event) {
    if (!this.hasComparisonChartOutlet) return

    const name = event.currentTarget.dataset.name
    if (!name) return

    const divSquare = event.currentTarget
    divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
      divSquare.classList.toggle(htmlClass)
    })
    divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
      divSquare.classList.toggle(htmlClass)
    })
    divSquare.querySelector('svg')?.classList?.toggle('hidden')

    this.comparisonChartOutlets.forEach(chart => { chart.legendClicked(name) })
  }

  legendAllOptionClicked (event) {
    if (!this.hasComparisonChartOutlet) return

    this.allOptionChecked = !this.allOptionChecked

    this.comparisonChartOutlets.forEach(chart => { chart.legendAllOptionClicked(this.allOptionChecked) })

    const currentTarget = event.currentTarget
    currentTarget.classList.remove('cursor-pointer')
    currentTarget.classList.add('cursor-wait')

    const controller = this

    setTimeout(function () {
      document.querySelectorAll('.legend-checkbox').forEach(function (divSquare) {
        if (controller.allOptionChecked) {
          divSquare.querySelector('svg')?.classList?.remove('hidden')
          divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
            divSquare.classList.add(htmlClass)
          })
          divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
            divSquare.classList.remove(htmlClass)
          })
        } else {
          divSquare.querySelector('svg')?.classList?.add('hidden')
          divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
            divSquare.classList.remove(htmlClass)
          })
          divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
            divSquare.classList.add(htmlClass)
          })
        }
      })

      currentTarget.querySelector('svg')?.classList?.toggle('hidden')

      currentTarget.classList.remove('cursor-wait')
      currentTarget.classList.add('cursor-pointer')
    }, 0)
  }
}
