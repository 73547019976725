import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class TeamChangesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    // setup the bar graph series (last one gets the line pattern)
    const colorConfig = {
      features: {
        bg: this.categoricalColors[1],
        hover: this.categoricalColors[1]
      },
      churn: {
        bg: this.categoricalColors[2],
        hover: this.categoricalColors[2]
      },
      refactor: {
        bg: this.categoricalColors[3],
        hover: this.categoricalColors[3]
      },
      other: {
        bg: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300],
        hover: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300]
      }
    }
    const datapointMetadata = {
      zIndex: 1,
      legendIndex: 0,
      minPointLength: 0,
      yAxis: 0,
      borderRadiusTopLeft: 0,
      borderRadiusTopRight: 0,
      marker: { enabled: false }
    }
    const featuresDelieveredSeriesData = []
    const churnSeriesData = []
    const refactorSeriesData = []
    const otherSeriesData = []
    const emptySeriesData = []
    for (let i = 0; i < controller.parsedGraphData.data.categories.length; i++) {
      let featuresDelieveredData = controller.parsedGraphData.data.features_delivered.data[i]
      let churnData = controller.parsedGraphData.data.churn.data[i]
      let refactorData = controller.parsedGraphData.data.refactor.data[i]
      let otherData = controller.parsedGraphData.data.other.data[i]
      if (featuresDelieveredData.y === 0 && churnData.y === 0 && refactorData.y === 0) {
        emptySeriesData.push({
          x: featuresDelieveredData.x,
          y: 0
        })
      }
      if (i === controller.parsedGraphData.data.categories.length - 1) {
        featuresDelieveredData = { ...featuresDelieveredData, ...this.getLastDataPointPattern(colorConfig.features) }
        churnData = { ...churnData, ...this.getLastDataPointPattern(colorConfig.churn) }
        refactorData = { ...refactorData, ...this.getLastDataPointPattern(colorConfig.refactor) }
        otherData = { ...otherData, ...this.getLastDataPointPattern(colorConfig.other) }
      }
      featuresDelieveredSeriesData.push(featuresDelieveredData)
      churnSeriesData.push(churnData)
      refactorSeriesData.push(refactorData)
      otherSeriesData.push(otherData)
    }

    const series = [
      {
        ...datapointMetadata,
        name: 'Features',
        data: featuresDelieveredSeriesData,
        color: this.categoricalColors[1]
      },
      {
        ...datapointMetadata,
        name: 'Churn',
        data: churnSeriesData,
        color: this.categoricalColors[2]
      },
      {
        ...datapointMetadata,
        name: 'Refactor',
        data: refactorSeriesData,
        color: this.categoricalColors[3]
      },
      {
        ...datapointMetadata,
        name: 'Other',
        data: otherSeriesData,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300]
      }
    ]

    if (emptySeriesData.length) {
      this.addNoDataSeriesToSeries(series, emptySeriesData)
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)

    // add the spline and the dashed line averages
    if (controller.parsedGraphData.max > 0) {
      series.push(
        {
          name: 'Pull requests per engineer',
          data: controller.parsedGraphData.data.features_per_engineer.data,
          color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500],
          lineColor: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500],
          zIndex: 2,
          type: 'spline',
          yAxis: 1,
          marker: { enabled: false }
        }
      )
      series.push(
        {
          name: 'Weekly avg',
          data: controller.parsedGraphData.data.weekly_avg_features_per_engineer.data,
          color: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
          lineColor: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
          zIndex: 2,
          type: 'line',
          dashStyle: 'ShortDash',
          yAxis: 1,
          marker: { enabled: false }
        }
      )
    }

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        tickPositions: controller.parsedGraphData.data.categories,
        tickLength: 0,
        title: { text: null },
        type: 'datetime',
        labels: {
          autoRotation: false,
          step: 2,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return Highcharts.dateFormat(controller.parsedGraphData.date_format, this.value)
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300]
      },

      yAxis: [
        {
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && !controller.parsedGraphData.max) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...maxSettings
        },
        {
          gridLineWidth: 0,
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && !controller.parsedGraphData.max) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...maxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 2,
          borderColor: controller.darkMode ? controller.tailwindColors.gray[800] : controller.tailwindColors.white,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0], this.total)
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        stickOnContact: true,
        formatter: function () {
          const total = this.points.reduce((acc, p) => {
            if (['Features', 'Churn', 'Refactor', 'Other'].includes(p.series.name)) {
              return acc + p.y
            }
            return acc
          }, 0)
          const breakDownValues = this.points.map((p, i) => {
            if (p.series.name === 'No data') {
              return null
            } else {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              let type = 'box'
              let value = p.y
              let percent = 0
              let style
              switch (p.series.name) {
                case 'Weekly avg':
                  type = 'dash'
                  style = `color: ${color};`
                  break
                case 'Pull requests per engineer':
                  type = 'line'
                  style = `background-color: ${color};`
                  break
                default:
                  if (total > 0) percent = Math.round((p.y / total) * 100)
                  value = `${value} <span class="text-gray-600 dark:text-gray-400">(${percent}%)</span>`
                  style = `background-color: ${color};`
              }
              return breakDownValue({
                name: p.series.name,
                value,
                style,
                type
              })
            }
          })

          const endDate = (this.x + (6 * 24 * 60 * 60 * 1000))
          const date = `${Highcharts.dateFormat('%B %e', this.x)}` + ' - ' + `${Highcharts.dateFormat('%B %e', endDate)}`

          return card({
            date: date,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-y-value="${Math.round(total)}" data-action="click->team-changes-delivered-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`,
            incomplete: this.points[0].point.index === (controller.parsedGraphData.data.categories.length - 1),
            primaryValues: [
              primaryValue({ name: 'Total pull requests', value: Math.round(total) })
            ],
            breakDownValues
          })
        }
      }
    }
  }
}
