import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

// Connects to data-controller="table-search"
export default class extends Controller {
  static targets = ['table', 'noSearchResultsRow']
  static debounces = [
    {
      name: 'search',
      wait: 150
    }
  ]

  connect () {
    useDebounce(this)
  }

  tableTargetConnected () {
    this.rows = this.tableTarget.querySelector('tbody').rows
  }

  search (event) {
    const searchText = event.target.value.toLowerCase()

    if (searchText.length === 0) {
      //  if search text is empty, remove hidden class from all rows
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].classList.contains('hidden')) this.rows[i].classList.remove('hidden')
      }
      if (this.hasNoSearchResultsRowTarget) this.noSearchResultsRowTarget.classList.add('hidden')
      return
    }

    //  loop through rows
    let countHidden = 0
    for (let i = 0; i < this.rows.length; i++) {
      //  if row contains search text, remove hidden class
      if (this.rows[i].dataset.searchable?.toLowerCase().includes(searchText)) {
        this.rows[i].classList.remove('hidden')
      } else {
        this.rows[i].classList.add('hidden')
        countHidden++
      }
    }

    // if "no search results" row exists, show it if all rows are hidden
    if (this.hasNoSearchResultsRowTarget) {
      if (countHidden === this.rows.length) this.noSearchResultsRowTarget.classList.remove('hidden')
      else this.noSearchResultsRowTarget.classList.add('hidden')
    }
  }
}
