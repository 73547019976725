import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class PortfolioOrganizationTrendStepChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const total = this.parsedGraphData.data.reduce((acc, curr) => acc + curr[1], 0)
    const series = [
      {
        step: 'center',
        data: this.parsedGraphData.data,
        marker: {
          enabled: false
        },
        lineColor: this.colorTheme.primary,
        color: this.colorTheme.primary,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [
              0,
              Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(this.darkMode ? 0.3 : 0.2)
                .get('rgba')
            ],
            [
              1,
              Highcharts.color(controller.tailwindColors.blue[500])
                .setOpacity(0)
                .get('rgba')
            ]
          ]
        },
        zoneAxis: 'x'
      }
    ]

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        sync: true,
        type: 'area',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b \'%y', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: {
          text: this.parsedGraphData.y_axis_label || '',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        labels: {
          format: '{text}' + `${this.parsedGraphData.y_axis_unit || ''}`,
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        startOnTick: false,
        ...maxSettings
      },
      plotOptions: {
        series: {
          connectNulls: true,
          step: 'center',
          cursor: 'pointer',
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const unit = this.y === 1 ? controller.parsedGraphData.unit : controller.parsedGraphData.unit_plural
          return card({
            date: `${Highcharts.dateFormat('%b \'%y', new Date(this.x))}`,
            primaryValues: [
              primaryValue({
                name: unit,
                value: controller.getNumericDisplay(this.y)
              })
            ],
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-action="click->portfolio-organization-trend-step-chart#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View engineers</button>`
          })
        },
        style: {
          zIndex: 100
        }
      },
      series: series
    }
  }
}
