import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="comparison-show"
export default class extends Controller {
  static targets = ['tab', 'chartView', 'splineChart', 'columnChart', 'splineChartButton', 'columnChartButton', 'table']
  static values = {
    tabName: { type: String, default: 'features_per_engineer' },
    chartType: { type: String, default: 'column' }
  }

  static classes = ['activeTab', 'inactiveTab', 'activeButton', 'inactiveButton']

  initialize () {
    // check dataset tabName for each chart and store it in a dictionary
    this.tabNameToChartView = {}
    this.tabNameToTable = {}
  }

  tabTargetConnected (tab) {
    if (tab.dataset.tabName === this.tabNameValue) {
      tab.classList.remove(...this.inactiveTabClasses)
      tab.classList.add(...this.activeTabClasses)
    } else {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    }
  }

  chartViewTargetConnected (chart) {
    const tabName = chart.dataset.tabName
    chart.classList.toggle('hidden', tabName !== this.tabNameValue)
    this.tabNameToChartView[tabName] = chart
  }

  // ensure that the correct type of chart stays visible
  columnChartTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'column')
  }

  splineChartTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'spline')
  }

  tableTargetConnected (table) {
    const tabName = table.dataset.tabName
    table.classList.toggle('hidden', tabName !== this.tabNameValue)
    this.tabNameToTable[table.dataset.tabName] = table
  }

  tabClicked (event) {
    event.preventDefault()
    // deactivate all tabs
    this.tabTargets.forEach(tab => {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    })
    // activate the clicked tab
    event.target.classList.remove(...this.inactiveTabClasses)
    event.target.classList.add(...this.activeTabClasses)

    // show the tab view that corresponds to the clicked tab
    this.tabNameValue = event.target.dataset.tabName

    // hide all tab views
    this.chartViewTargets?.forEach(chart => { chart.classList.add('hidden') })
    this.tableTargets?.forEach(table => { table.classList.add('hidden') })

    // show the tab view that corresponds to the selected tab
    this.tabNameToChartView[this.tabNameValue]?.classList?.remove('hidden')
    this.tabNameToTable[this.tabNameValue]?.classList?.remove('hidden')

    // update the url
    this.setUrlParams('breakdown', this.tabNameValue)
  }

  setUrlParams (paramName, paramValue) {
    const searchParams = new URLSearchParams(window.location.search)
    // add new state to url
    if (paramValue) {
      // the breakdown exists
      searchParams.set(paramName, paramValue)
    } else {
      // None case
      searchParams.delete(paramName)
    }
    const url = [
      window.location.protocol,
      '//',
      window.location.host,
      window.location.pathname,
      '?',
      searchParams
    ].join('')

    // front arrow won't work
    history.replaceState({}, null, url)

    // update the hidden form fields if they exist
    if (document.getElementById(paramName)) document.getElementById(paramName).value = paramValue
  }

  showSplineChart () {
    this.showChartType('spline')
  }

  showColumnChart () {
    this.showChartType('column')
  }

  showChartType (type) {
    this.chartTypeValue = type
    this.columnChartTargets.forEach(chart => { chart.classList.toggle('hidden', type !== 'column') })
    this.splineChartTargets.forEach(chart => { chart.classList.toggle('hidden', type !== 'spline') })
    this.setUrlParams('chart_type', type)
  }
}
