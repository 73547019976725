import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="git-user-group"
export default class extends Controller {
  static targets = [
    'renameView', 'editRenameView', 'nameInput', // name input
    'costInputEditButton', 'costInputView', 'editCostInputView', 'editCostInputViewButtons', 'costInput', // cost input
    'membersView', 'editMembersView', // members input
    'repositoriesView', 'editRepositoriesView', // repositories input
    'tab', 'tabView' // for the tab select
  ]

  static classes = ['activeTab', 'inactiveTab']

  initialize () {
    this.tabName = 'engineers'
  }

  tabTargetConnected (tab) {
    if (tab.dataset.tabName === this.tabName) {
      tab.classList.remove(...this.inactiveTabClasses)
      tab.classList.add(...this.activeTabClasses)
    } else {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    }
  }

  tabViewTargetConnected (tabView) {
    tabView.classList.toggle('hidden', tabView.dataset.tabName !== this.tabName)
  }

  showEditRenameView () {
    this.renameViewTarget.classList.add('hidden')
    this.editRenameViewTarget.classList.remove('hidden')
    this.nameInputTarget.focus()
  }

  hideEditRenameView () {
    this.renameViewTarget.classList.remove('hidden')
    this.editRenameViewTarget.classList.add('hidden')
  }

  showEditCostInputView () {
    this.costInputViewTarget.classList.add('hidden')
    this.costInputEditButtonTarget.classList.add('hidden')
    this.editCostInputViewTarget.classList.remove('hidden')
    this.editCostInputViewButtonsTarget.classList.remove('hidden')
    this.costInputTarget.focus()
  }

  hideEditCostInputView () {
    this.costInputViewTarget.classList.remove('hidden')
    this.costInputEditButtonTarget.classList.remove('hidden')
    this.editCostInputViewTarget.classList.add('hidden')
    this.editCostInputViewButtonsTarget.classList.add('hidden')
  }

  showEditMembersView () {
    this.membersViewTarget.classList.add('hidden')
    this.editMembersViewTarget.classList.remove('hidden')
  }

  hideEditMembersView () {
    this.membersViewTarget.classList.remove('hidden')
    this.editMembersViewTarget.classList.add('hidden')
  }

  showEditRepositoriesView () {
    this.repositoriesViewTarget.classList.add('hidden')
    this.editRepositoriesViewTarget.classList.remove('hidden')
  }

  hideEditRepositoriesView () {
    this.repositoriesViewTarget.classList.remove('hidden')
    this.editRepositoriesViewTarget.classList.add('hidden')
  }

  selectTab (event) {
    this.tabName = event.currentTarget.dataset.tabName
    this.showActiveTab()
  }

  showActiveTab () {
    this.tabTargets.forEach(tab => {
      if (tab.dataset.tabName === this.tabName) {
        tab.classList.remove(...this.inactiveTabClasses)
        tab.classList.add(...this.activeTabClasses)
      } else {
        tab.classList.remove(...this.activeTabClasses)
        tab.classList.add(...this.inactiveTabClasses)
      }
    })
    this.tabViewTargets.forEach(tabView => {
      tabView.classList.toggle('hidden', tabView.dataset.tabName !== this.tabName)
    })
  }
}
