export function colorConfigOptions (controller) {
  return [
    {
      color: controller.darkMode ? controller.tailwindColors.blue[700] : controller.tailwindColors.blue[200],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.blue[600] : controller.tailwindColors.blue[300]
        }
      }
    },
    {
      color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.gray[500] : controller.tailwindColors.gray[300]
        }
      }
    }
  ]
}
