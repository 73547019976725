import { Controller } from '@hotwired/stimulus'
import { newTracker, trackPageView } from '@snowplow/browser-tracker'

export default class SnowplowTrackingController extends Controller {
  static values = {
    snowplowTrackerApiHost: String
  }

  connect () {
    if (!window.snowplowTracker) {
      this.initSnowplowTracker()
    }

    this.trackPage()
  }

  initSnowplowTracker () {
    const snowplowCollectorUrl = this.snowplowTrackerApiHostValue

    window.snowplowTracker = newTracker(
      'snowplow-tracker-app',
      snowplowCollectorUrl,
      {
        appId: 'software-app',
        platform: 'web',
        postPath: '/com.software/web_events'
      }
    )
  }

  trackPage () {
    trackPageView()
  }
}
