import { Controller } from '@hotwired/stimulus'

export default class ClipboardController extends Controller {
  static targets = ['copy', 'input']
  static values = { success: String }

  copy () {
    this.inputTarget.select()
    // for mobile devices
    this.inputTarget.setSelectionRange(0, 99999)
    document.execCommand('copy')
    this.copyTarget.innerHTML = this.hasSuccessValue ? this.successValue : 'Copied!'
  }
}
