import BaseGraph from './base_graph_controller'
import { dateToHoursMinutesDisplay } from '../helpers/highcharts_helper'

export default class MeetingVsCodeGraph extends BaseGraph {
  pointFormatter () {
    const date = new Date(0, 0)
    date.setSeconds(Math.abs(this.y) * 60 * 60)
    const displayValue = dateToHoursMinutesDisplay(date)
    return `
      <tr>
        <td style="color: black; padding: 4px;">${this.series.name}: </td>
        <td style="padding: 4px;"><b>${displayValue}</b></td>
      </tr>
    `
  }

  referenceLine () {
    const referenceClass = localStorage.getItem('dashboard_reference_class') || 'default'

    const defaults = { type: 'jump-line', grouping: false, pointPadding: 0.1, groupPadding: 0 }

    if (referenceClass === 'global') {
      return {
        ...defaults,
        name: 'Global 90-day avg meeting time',
        data: this.parsedGraphData.global_avg
      }
    } else {
      return {
        ...defaults,
        name: 'Your 90-day avg meeting time',
        data: this.parsedGraphData.avg_90_day
      }
    }
  }

  prepareGraphSchema () {
    const controller = this

    return {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif'
        },
        backgroundColor: 'transparent',
        events: {
          render () {
            controller.renderDashedJumpLines(this)
          }
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        lineColor: this.colorTheme.gridLine,
        labels: {
          style: {
            color: '#848C99'
          }
        }
      },
      yAxis: {
        labels: {
          formatter: function () {
            return `${Math.abs(this.value)}h`
          },
          style: {
            color: '#848C99'
          }
        },
        title: { text: '' },
        gridLineColor: this.colorTheme.gridLine
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormatter: this.pointFormatter,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [
        {
          name: 'Meeting Time',
          data: this.parsedGraphData.meeting_time,
          color: this.colorTheme.meetingTime,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent'
        },

        {
          name: 'Active Code Time',
          data: this.parsedGraphData.active_code_time,
          color: this.colorTheme.activeCodeTime,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent'
        },
        this.referenceLine()
      ],
      legend: false
    }
  }
}
