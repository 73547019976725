import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { colorConfigOptions } from '../helpers/copilot_helper'

export default class CopilotKpiTrendController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const colorConfig = colorConfigOptions(this)
    this.USDFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })
    const yValues = controller.parsedGraphData.series.length
      ? controller.parsedGraphData.series[0].data.map((datum) => datum.y)
      : []
    const max = Math.max(
      0,
      ...yValues,
      controller.parsedGraphData.average
    )

    const dataLen = controller.parsedGraphData.series[0].data.length
    const copilotCurrent = controller.parsedGraphData.series[0].data.map((datum, i) => {
      return {
        x: datum.x,
        y: i === dataLen - 1 ? datum.y : null
      }
    })

    if (dataLen) {
      controller.parsedGraphData.series[0].data[dataLen - 1] = {
        x: controller.parsedGraphData.series[0].data[dataLen - 1].x, y: null
      }
    }
    const series = controller.parsedGraphData.series.map((datum, i) => {
      return {
        ...datum,
        ...colorConfig[i]
      }
    })

    if (dataLen) {
      series.push(
        {
          data: copilotCurrent,
          ...this.getLastDataPointPattern(colorConfig[0]),
          minPointLength: 5,
          name: controller.parsedGraphData.series[0].name
        }
      )
    }
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(max)
    if (max) {
      // use max to allow the graph to show the average line
      maxSettings.max = max
    }
    const plotLines = [
      {
        zIndex: 50,
        color: controller.tailwindColors.gray[400],
        value: controller.parsedGraphData.average,
        dashStyle: 'ShortDash',
        width: 2
      }
    ]
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: false
        },
        gridLineColor: 'transparent',
        lineWidth: 0,
        maxPadding: 0
      },
      yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        endOnTick: true,
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: true,
          pointPadding: 0.1,
          groupPadding: 0,
          minPointLength: 5,
          borderColor: 'transparent',
          connectNulls: true,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          pointPlacement: 'between'
        },
        series: {
          dataLabels: {
            enabled: false
          },
          cursor: 'pointer',
          stickyTracking: false
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        outside: false,
        positioner: function (w, h) {
          return {
            x: -(w / 2),
            y: -h
          }
        },
        formatter: function () {
          const startOfPeriod = new Date(this.x)
          const startDateCopy = new Date(startOfPeriod)
          let endOfPeriod = null
          switch (controller.optionsValue.date_granularity.toLowerCase()) {
            case 'week':
              endOfPeriod = startOfPeriod.getTime() + (24 * 60 * 60 * 1000 * 6)
              break
            case 'month':
              endOfPeriod = new Date(startDateCopy.setMonth(startDateCopy.getMonth() + 1)).getTime()
              break
            case 'quarter':
              endOfPeriod = new Date(startDateCopy.setMonth(startDateCopy.getMonth() + 3)).getTime()
              break
          }
          const date = `${Highcharts.dateFormat('%b %e', startOfPeriod)} - ${Highcharts.dateFormat('%b %e', endOfPeriod)}`
          let value = this.y
          if (controller.optionsValue.unit === 'day') {
            value = controller.daysOrHoursString(value)
          } else if (controller.optionsValue.unit === '%') {
            value = `${value}%`
          } else if (controller.optionsValue.unit === '$') {
            value = controller.USDFormatter.format((value / 100))
          } else {
            value = `${Math.round(value * 10) / 10} / ${controller.optionsValue.unit}`
          }
          const color = this.color.pattern ? this.color.pattern.color : this.color
          const breakDownValues = [
            breakDownValue({
              name: this.series.name,
              value,
              style: `background-color: ${color};`,
              type: 'box'
            })
          ]

          return card({
            date,
            breakDownValues
          })
        }
      }
    }
  }
}
