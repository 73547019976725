import { Controller } from '@hotwired/stimulus'

export default class DashboardController extends Controller {
  static targets = [
    'youElement',
    'globalElement',
    'refSelector',
    'weekSelector',
    'refClassYou',
    'refClassGlobal',
    'flashContainer',
    'dashboardCards'
  ]

  static values = {
    currentDashboard: String,
    type: String
  }

  connect () {
    // when the dashboard connects, check what reference class to show
    this.checkReferenceClass()

    // add a listener to refresh elements on the page when the reference class changes
    this.boundCheckReferenceClass = this.checkReferenceClass.bind(this)
    document.addEventListener(
      'ref_class_changed',
      this.boundCheckReferenceClass
    )
  }

  referenceClassClicked (e) {
    // set reference class in localStorage
    const refClass = e.target.dataset.value
    localStorage.setItem('dashboard_reference_class', refClass)

    // emit ref_class_changed event
    const event = new CustomEvent('ref_class_changed', { detail: { refClass } })
    document.dispatchEvent(event)
  }

  // checks the dashboard_reference_class stored in localStorage
  checkReferenceClass (e) {
    const referenceClass =
      localStorage.getItem('dashboard_reference_class') || 'default'

    if (referenceClass === 'default') {
      this.showGlobalReferenceClass()
    } else {
      this.hideGlobalReferenceClass()
    }
  }

  hideGlobalReferenceClass () {
    // remove blue from "Global" button and make the "You" button blue
    if (this.hasRefClassGlobalTarget) {
      this.refClassGlobalTarget.classList.remove('text-blue')
      this.refClassYouTarget.classList.add('text-blue')
    }

    // hide all global elements, show all "You" elements
    this.globalElementTargets.forEach(el => el.classList.add('hidden'))
    this.youElementTargets.forEach(el => el.classList.remove('hidden'))
  }

  showGlobalReferenceClass () {
    // remove blue from "You" button, make the "Global" button blue
    if (this.hasRefClassGlobalTarget) {
      this.refClassGlobalTarget.classList.add('text-blue')
      this.refClassYouTarget.classList.remove('text-blue')
    }

    // hide all "You" elements, show all global elements
    this.globalElementTargets.forEach(el => el.classList.remove('hidden'))
    this.youElementTargets.forEach(el => el.classList.add('hidden'))
  }
}
