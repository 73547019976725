import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { colorConfigOptions } from '../helpers/copilot_helper'

export default class CopilotOrganizationUsageChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const colorConfig = colorConfigOptions(this)

    const dataLen = controller.parsedGraphData.series[0].data.length
    const copilotCurrent = controller.parsedGraphData.series[0].data[dataLen - 1] || 0
    controller.parsedGraphData.series[0].data[dataLen - 1] = null
    const series = controller.parsedGraphData.series.map((datum, i) => {
      const rounding = i === dataLen - 1 ? 2 : 0
      return {
        ...datum,
        ...colorConfig[i],
        minPointLength: 5,
        borderRadiusTopLeft: rounding,
        borderRadiusTopRight: rounding
      }
    })
    if (controller.parsedGraphData.max) {
      const copilotData = new Array(dataLen).fill(null)
      copilotData[dataLen - 1] = copilotCurrent
      series.push(
        {
          data: copilotData,
          ...this.getLastDataPointPattern(colorConfig[0]),
          minPointLength: 5,
          name: controller.parsedGraphData.series[0].name
        }
      )
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)
    maxSettings.maxPadding = undefined
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          autoRotation: false,
          step: controller.parsedGraphData.categories.length > 6 ? 3 : 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && !controller.parsedGraphData.max) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        reversedStacks: false,
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 0,
          minPointLength: 5,
          borderColor: controller.darkMode ? controller.tailwindColors.gray[800] : controller.tailwindColors.white,
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          stickyTracking: false
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            const color = p.color.pattern ? p.color.pattern.color : p.color
            return breakDownValue({
              name: p.series.name,
              value: `${p.y}%`,
              style: `background-color: ${color};`,
              type: 'box'
            })
          }).reverse()

          return card({
            date: this.x,
            breakDownValues
          })
        }
      }
    }
  }
}
