import BaseGraph from './base_graph_controller'
import { colorConfigOptions } from '../helpers/copilot_helper'

export default class extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const colorConfig = colorConfigOptions(this)
    const dataSeries = []
    controller.parsedGraphData.data.forEach((datum, i) => {
      dataSeries.push({
        ...datum,
        ...colorConfig[i]
      })
    })
    const series = [
      {
        data: dataSeries
      }
    ]
    const total = controller.parsedGraphData.data.reduce((acc, item) => acc + item.y, 0)
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)
    maxSettings.maxPadding = undefined

    return {
      chart: {
        type: 'column',
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0
      },
      yAxis: {
        title: { text: null },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && !total) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        ...maxSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          borderColor: 'transparent',
          minPointLength: 5,
          colorByPoint: true,
          maxPointWidth: 150,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'justify',
            formatter: function () {
              return controller.formatValue(this.y || '--')
            },
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.white
                : controller.tailwindColors.black
            }
          }
        }
      },
      colors: this.breakdownColors,
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        outside: false,
        formatter: function () {
          return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
            <div class="font-medium text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>
            <div class="flex justify-between items-center gap-2 mt-3">
              <div class="flex items-center gap-1">
                <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                <div class="font-medium text-black dark:text-white">${this.point.name}</div>
              </div>
              <div class="font-semibold text-black dark:text-white">${controller.formatValue(this.y)}</div>
            </div>
          </div>
          `
        }
      }
    }
  }

  formatValue (value) {
    return `${value} per ${this.optionsValue.date_granularity.toLowerCase()}`
  }
}
