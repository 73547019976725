import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    this.setupSelect()

    this.element.closest('form').addEventListener('reset', (e) => {
      this.disconnect()
      this.setupSelect()
    })
  }

  setupSelect () {
    const plugins = ['dropdown_input']

    if (this.element.dataset.plugins) {
      plugins.push(...JSON.parse(this.element.dataset.plugins))
    } else if (this.element.multiple) {
      plugins.push('remove_button')
    }

    const options = {
      plugins,
      maxOptions: null
    }

    this.tomSelect = new TomSelect(this.element, options)
  }

  toggleSubmitButton (event) {
    this.submitButtonTarget.disabled = !event.currentTarget.value
  }

  disconnect () {
    this.tomSelect?.destroy()
  }
}
