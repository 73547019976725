// The following will instruct Turbo to show our own custom dialog instead of the browsers
// default confirm prompt. Use `data-turbo-confirm` on the form/button to enable the
// dialog. The other `turbo-confirm` attributes are optional and allow additional
// customization of the default dialog that is located in the `application.html.erb`.
//
// <%= button_to(
//   'Remove SSO',
//   organization_sso_config_path(@sso_config.organization),
//   method: :delete,
//   class: 'btn-danger',
//   data: {
//     turbo_confirm: 'Are you sure you want to delete SSO for your organization?',
//     turbo_confirm_details: 'Anyone using Okta to sign in to Software.com will lose access. You can always reconnect SSO later.',
//     turbo_confirm_button_class: 'btn-danger',
//     turbo_confirm_button_text: 'Remove SSO'
//   }
// ) %>
window.Turbo.setConfirmMethod((message, element) => {
  // Turbo does not always send the element with the data-turbo-confirm attribute when
  // using a button_to. It sends the wrapping <form>, so we need to find the correct
  // element in the form.
  // https://github.com/hotwired/turbo/issues/811
  let confirmData
  if (element.dataset.turboConfirm === message) {
    confirmData = element.dataset
  } else {
    confirmData = element.querySelector(`[data-turbo-confirm="${message}"]`).dataset
  }

  const dialog = document.getElementById('turbo-confirm-dialog')
  const originalDialog = dialog.cloneNode(true)

  dialog.querySelector('#turbo-confirm-prompt').textContent = message

  if (confirmData.turboConfirmDetails) {
    dialog.querySelector('#turbo-confirm-details').textContent = confirmData.turboConfirmDetails
  }

  const confirmButton = dialog.querySelector('#turbo-confirm-button')
  if (confirmData.turboConfirmButtonClass) { confirmButton.className = confirmData.turboConfirmButtonClass }
  if (confirmData.turboConfirmButtonText) { confirmButton.textContent = confirmData.turboConfirmButtonText }

  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener('close', () => {
      // reset the dialog to it's original state when closed
      dialog.replaceWith(originalDialog.cloneNode(true))
      resolve(dialog.returnValue === 'confirm')
    }, { once: true })
  })
})
